import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListFlatComponent } from './list-flat.component';
import { ListFlatItemComponent } from './list-flat-item.component';
import { ListGroupComponent } from '@app/shared/ux/lists/list-group.component';

@NgModule({
    declarations: [
        ListFlatComponent,
        ListFlatItemComponent,
        ListGroupComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ListFlatComponent,
        ListFlatItemComponent,
        ListGroupComponent,
    ]
})
export class ListModule {}
