import { Component, Inject, Input } from '@angular/core';
import { ListFlatComponent } from './list-flat.component';

@Component({
    selector: 'list-flat-item',
    templateUrl: 'list-flat-item.component.html',
    styleUrls: ['list-flat-item.component.scss'],
})

export class ListFlatItemComponent {

    constructor(
        @Inject(ListFlatComponent) public parent: ListFlatComponent
    ) {}
}
