import { Component, Input } from '@angular/core';

@Component({
    selector: 'list-group',
    templateUrl: './list-group.component.html',
    styleUrls: ['./list-group.component.scss', './list-flat.component.scss']
})
export class ListGroupComponent {
    @Input() icon: boolean = false;
    @Input() dropdown: boolean = false;
    @Input() flat: boolean = false;
    @Input() flatIcon: boolean = false;
    @Input() lined: boolean = false;

    constructor() {}

}
